
    // @ is an alias to /src
    import DevInstallationCards from "@/components/dev/dev_installation_cards.vue";
    import VuePrismEditor from "vue-prism-editor";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            DevInstallationCards,
            VuePrismEditor,
        },
    })

    // *****************************************************************************************************************
    export default class DevInstallationList extends Vue {
        // Data function
        public data() {
            return {
                itIconPathAndName:
                    require("@/assets/images/icon_it.png"),

                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),

                ninite:
                    require("@/assets/images/ninite.png"),

                oculusAppImagePathAndName:
                    require("@/assets/images/oculus_app.png"),

                userVariablesAndroid:
                    "C:\\Android             (Android SDK)",

                userVariablesPath:
                    "C:\\Users\\[You]\\AppData\\Local\\Programs\\Python37\\Scripts\\  (Python)" + "\n" +
                    "C:\\Users\\[You]\\AppData\\Local\\Programs\\Python37\\          (Python)" + "\n" +
                    "C:\\Users\\[You]\\AppData\\Roaming\\npm                       (Node.js package manager)" + "\n" +
                    "C:\\Program Files (x86)\\UnxUtils\\usr\\local\\wbin           (Unix Utils)",

                systemVariablesJavaHome:
                    "C:\\Program Files (x86)\\Java\\jre1.8.0_261                 (Java SDK)",

                systemVariablesPath:
                    "C:\\Dev\\Android\\platform-tools\\                           (Android SDK Platform Tools)" + "\n" +
                    "C:\\Program Files\\nodejs\\                                 (NodeJS)" + "\n" +
                    "%SYSTEMROOT%\\System32\\WindowsPowerShell\\v1.0\\            (PowerShell)" + "\n" +
                    "D:\\Program Files\\Git\\cmd                                 (Git)" + "\n" +
                    "%SYSTEMROOT%\\System32\\OpenSSH\\                           (OpenSSH)" + "\n" +
                    "C:\\Program Files\\Microsoft VS Code\\bin                   (VS Code)",

                systemVariablesPsModulePath:
                    "%SystemRoot%\\system32\\WindowsPowerShell\\v1.0\\Modules\\    (PowerShell)" + "\n" +
                    "C:\\Program Files (x86)\\AWS Tools\\PowerShell\\             (PowerShell)",

                systemsIconPathAndName:
                    require("@/assets/images/icon_systems.png"),
            };
        }
    }
