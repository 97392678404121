
    // @ is an alias to /src
    import CarouselInstallationCard from "@/components/carousel_cards/carousel_installation_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselInstallationCard,
        },
    })

    // *****************************************************************************************************************
    export default class DevInstallationCards extends Vue {
        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                airVpnImagePathAndName:
                    require("@/assets/images/air_vpn.png"),

                androidSdkPlatformToolsImagePathAndName:
                    require("@/assets/images/android_sdk_platform_tools.png"),

                beyondCompareImagePathAndName:
                    require("@/assets/images/beyond_compare.png"),

                blenderImagePathAndName:
                    require("@/assets/images/blender.png"),

                chromeImagePathAndName:
                    require("@/assets/images/chrome.png"),

                gitForWindowsImagePathAndName:
                    require("@/assets/images/git_for_windows.png"),

                gitKrakenImagePathAndName:
                    require("@/assets/images/gitkraken.png"),

                nodejsImagePathAndName:
                    require("@/assets/images/node_js.png"),

                notepadPlusPlusImagePathAndName:
                    require("@/assets/images/notepad_plus_plus.png"),

                photoshopImagePathAndName:
                    require("@/assets/images/photoshop.png"),

                powershellImagePathAndName:
                    require("@/assets/images/powershell.png"),

                pythonImagePathAndName:
                    require("@/assets/images/python.png"),

                sharexImagePathAndName:
                    require("@/assets/images/sharex.png"),

                unityImagePathAndName:
                    require("@/assets/images/unity.png"),

                visualStudioCodeImagePathAndName:
                    require("@/assets/images/visual_studio_code.png"),

                yarnImagePathAndName:
                    require("@/assets/images/yarn.png"),
            };
        }
    }
